<template>
  <div>
      <el-menu :default-active="type" class="el-menu-demo" mode="horizontal" @select="changeHandle">
        <!-- <el-menu-item index="">全部课程</el-menu-item> -->
        <el-menu-item index="gxk">公需课</el-menu-item>
        <el-menu-item index="yx">药学</el-menu-item>
        <el-menu-item index="zyx">中药学</el-menu-item>
      </el-menu>
    <template v-for="item in datalist" :key="item.id">
    <div style="display: -webkit-flex;display: flex;margin:10px">
      <el-image style='width:150px;height:92px;' :src="item.coverUrl"/>
      <div style="flex: 1;margin-left:10px">
        <div @click="video(item.id)" style="text-decoration:underline;cursor:pointer">[{{item.major}}]{{item.name}}</div>
        <div style="margin-top:10px">授课老师：{{item.teacherName}}</div>
        <div style="margin-top:10px">
          <el-progress v-if="item.rate>0" :percentage="item.rate"/>
          <span v-if="item.rate==100" style="color:#09bb07">已学完</span>
        </div>
      </div>
    </div>
    <div style="height:2px;background-color: #eee;"></div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type:'gxk',
      datalist: [],
    };
  },
  mounted: function () {
    this.getDatalist("gxk")
  },
  methods:{
    getDatalist (key) {
      this.$net.courseList(key).then(({ data }) => {
        this.datalist = data.list.map(this.getRateTxt);
      });
    },
    changeHandle (key) {
      this.getDatalist(key)
    },
    video (id) {
      this.$router.push({path:'/video/'+id})
    },
    getRateTxt (course) {
        if(course.record){
            if(course.record.isFinished){
                course.rate = 100
                course.rateTxt = '(已学完)'
            }else{
                course.rate = (course.record.mins / course.mins * 100).toFixed(0)*1
                if(course.rate>=100){
                    course.rate = 99
                }
                course.rateTxt = '('+course.rate+'%)'
            }
        }
        return course
    },
  }
};
</script>